#lottie-wrapper {
    z-index: 999999;
    position: fixed;
    inset: 50% auto auto 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 600px;
    height: 600px;
    display:block;
}
