
.image-content-zoom {
    display: flex;
    border: 2px solid var(--cool-gray);
    border-style: dashed;
    border-radius: 8px;
    padding: 16px;
    height: auto;
}

.image-content-zoom img {
    margin: auto;
    max-width: 100%;
}
