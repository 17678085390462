.chart {
    height: 360px;
}

.message-no-data {
    text-align: center;
    font-size: 25px;
    height: 100%;
}

.chart-content {
    overflow-y: auto;
    height: 360px;
}

.fontSizeTitle {
    font-size: 1.2rem;
    margin-right: 12px;
}
