@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~react-toastify/scss/main';

$base-color: #D82239;
$cool-gray: #302828;

@font-face {
    font-family: omneshuggies;
    src: local(omneshuggies), url('./fonts/OmnesHuggies.otf') format('truetype');
}

body {
    font-family: omneshuggies !important;
}

:root {
    --cool-gray: #302828;
}


#root {
    height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.nav-link {
    text-decoration: none !important;
    color: #D82239 !important;
}


.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
    height: 94px !important;
    padding: 10px;
    text-align: center;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #D82239 !important;
    color: white !important;
}

.menu-open  > .nav-link {
    background-color: #D82239 !important;
    color: white !important;
}

[class*="sidebar-dark-"] {
    //background-color: #D82239;
}

.card-primary.card-outline {
    border-top: 3px solid $base-color;
}