/**
    Main App SCSS
*/

#terms-root {
    text-align: left;
    width: 80%;
    max-width: 900px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 100px;
}

#terms-root h1 {
    font-size: 24px;
    color: var(--cool-gray);
    text-align: center;
}

.ck.ck-editor {
    max-width: 100%;
    width: 100% !important;
}

#lottie-wrapper {
    z-index: 999999;
    position: fixed;
    inset: 50% auto auto 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 600px;
    height: 600px;
}

.icon-button {
    margin-top: 3px;
    margin-left: 2px;
}

.pl-1, .px-1{
    width: 100% !important;
}